import { apiRoutes } from './api-routes';
import { rolePermissions } from './role-permissions';

export const environment = {
  production: false,
  hmr: false,
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/home',
    redirectToWhenUnauthenticated: '/auth/login',
  },
  api: {
    baseUrl: 'https://api-dev.effort.ligafacens.com',
    maxRequestRetries: 3,
    routes: apiRoutes,
  },
  rolePermissions: rolePermissions,
};
