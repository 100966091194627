//#region Imports

import { Component } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { Rive } from '@rive-app/canvas';

//#endregion

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
})
export class LoadingPageComponent {
  constructor(private readonly router: Router) {
    let riveInstance: Rive | null = null;

    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        clearTimeout(this.cleanupDebounceTime);

        this.showLoading = true;

        if (riveInstance) {
          riveInstance.play();

          return;
        }

        const canvas = document.getElementById(
          'loading-page-canvas',
        ) as HTMLCanvasElement | null;

        if (!canvas) {
          return;
        }

        riveInstance = new Rive({
          src: '/assets/rive/loading.riv',
          canvas,
          autoplay: true,
          onLoad: () => {
            riveInstance?.resizeDrawingSurfaceToCanvas();
          },
        });
      } else if (event instanceof RouteConfigLoadEnd) {
        this.showLoading = false;

        this.cleanupDebounceTime = setTimeout(() => {
          riveInstance?.stopRendering();
          riveInstance?.cleanup();
          riveInstance = null;
        }, 100);
      }
    });
  }

  //#region Public Properties

  public showLoading: boolean = true;

  //#endregion

  //#region Private Properties

  private cleanupDebounceTime?: ReturnType<typeof setTimeout>;

  //#endregion
}
