//#region Imports

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { mergeMap } from 'rxjs/operators';
import { UserService } from '../../../services/user/user.service';
import { HttpAsyncConfig } from '../models/http-async.config';
import { HTTP_ASYNC_CONFIG } from '../models/injection-tokens';

//#endregion

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(
    protected readonly userService: UserService,
    @Inject(HTTP_ASYNC_CONFIG)
    @Optional()
    protected readonly config?: HttpAsyncConfig,
  ) {}

  //#region Public Static Properties

  public static readonly DISABLE_HEADER: string = 'X-Disabled-BearerToken';

  //#endregion

  //#region Public Methods

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.config?.bearerTokenKey) {
      console.warn(
        'Você incluiu o Interceptor para adicionar o Bearer Token a requisição ' +
          'mas não configurou a chave para buscar o valor do cache no módulo.',
      );

      return next.handle(req);
    }

    if (!req.headers.get(BearerTokenInterceptor.DISABLE_HEADER)) {
      return fromPromise(this.userService.getUserToken()).pipe(
        mergeMap((result) => {
          if (!result) return next.handle(req);

          const headers = req.headers.set('Authorization', result.token);

          req = req.clone({
            headers,
          });

          return next.handle(req);
        }),
      );
    }

    req = req.clone({
      headers: req.headers.delete(BearerTokenInterceptor.DISABLE_HEADER),
    });

    return next.handle(req);
  }

  //#endregion
}
