import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetCustomFieldProxy } from '@app/models/proxies/asset-custom-field.proxy';
import { AssetFormFieldTemplateProxy } from '@app/models/proxies/asset-form-field-template.proxy';
import { AssetFormFieldProxy } from '@app/models/proxies/asset-form-field.proxy';
import { AssetCustomFieldTemplateProxy } from '@app/models/proxies/custom-field-folder-template.proxy';
import { CustomFieldType } from '../../models/enums/custom-field-type.enum';
import { FormFieldType } from '../../models/enums/form-field-type.enum';
import { SubmitCustomFieldWithOptionalTemplatePayload } from '../../models/payloads/submit-custom-field-with-optional-template.payload';
import { FileInformation } from './file-information';
import { NullableProperties } from './nullable-properties';

export async function delay(ms: number): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export function getCrudErrors({ status, error }: any): string[] {
  if (!error || (status >= 500 && status <= 599)) {
    return ['Ocorreu um erro interno, por favor, tente novamente.'];
  }

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return [
        'A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.',
      ];

    return [
      error.message ||
        'Ocorreu um erro inesperado, por favor, contate os administradores se o erro persistir.',
    ];
  }

  if (error.message.every((message: any) => typeof message === 'string'))
    return error.message;

  return error.message
    .map(({ constraints }: any) =>
      constraints ? Object.values(constraints) || [] : [],
    )
    .reduce((acc: any, actual: any) => [...acc, ...actual] as string[]);
}

export function isString(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object String]';
}

export function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0');
}

export function formatDate(date: Date): string {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    'T' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

export function removeMultiplesSpaces(event: Event): void {
  const fullText: string = (event.target as HTMLInputElement).value;

  if (fullText === '') return;

  (event.target as HTMLInputElement).value = fullText.replace(/ {2,}/g, ' ');
}

export function sortArrayOfObjects(
  data: any[],
  keyToSort: keyof any,
  direction: 'ascending' | 'descending' | 'none',
): any[] {
  if (direction === 'none') return data;

  const compare = (objectA: any, objectB: any): number => {
    const valueA = objectA[keyToSort];
    const valueB = objectB[keyToSort];

    if (valueA === valueB) return 0;

    if (valueA > valueB) return direction === 'ascending' ? 1 : -1;
    else return direction === 'ascending' ? -1 : 1;
  };

  return data.sort(compare);
}

export function removeCNPJMask(cnpj: string): string {
  return cnpj.replace(/[^\d]/g, '');
}

export function checkPassword(password: string): void {
  password = password.trim();

  const hasUpper = (str: string): boolean => /[A-Z]/.test(str);
  const hasLower = (str: string): boolean => /[a-z]/.test(str);
  const hasNumber = (str: string): boolean => /[0-9]/.test(str);

  if (password?.length < 6)
    throw new Error('A senha deve ter mais que 6 caracteres');

  if (!hasUpper(password) || !hasLower(password))
    throw new Error(
      'A senha deve ter pelo menos uma letra maiúscula e minúscula',
    );

  if (!hasNumber(password))
    throw new Error('A senha deve ter pelo menos um número');
}

/**
 * @example ....('field.subField', content) -> return content.field.subField
 */
export function getObjectDeepProperty(fieldPath: string, content: any): any {
  const fields: string[] = fieldPath.split('.') || [];

  if (fields.length > 3)
    throw new Error('The maximum number of possible fields is three');

  if (fields.length === 0) throw new Error('Fields is empty');

  while (fields.length > 0) {
    content = content[fields.shift()!];
  }

  return content;
}

export function removeNullableFields<T extends object>(obj: T): T {
  const newObj = { ...obj };

  for (const key in newObj) if (!isValid(newObj[key])) delete newObj[key];

  return newObj;
}

export function setNullAtFalsyFields<T extends object>(
  obj: T,
): NullableProperties<T> {
  const newObj = { ...obj } as NullableProperties<T>;

  for (const key in newObj) {
    const isEmptyString =
      typeof newObj[key] === 'string' &&
      !(newObj[key] as string)?.trim().length;

    const isNonBooleanFalsy = !newObj[key] && typeof newObj[key] !== 'boolean';

    if (isEmptyString || isNonBooleanFalsy) newObj[key] = null;
  }

  return newObj;
}

export async function readFileAsBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onloadend = () => {
      if (typeof reader.result !== 'string') return;

      resolve(reader.result);
    };

    reader.readAsDataURL(file);
  });
}

export async function getFileInformation(file: File): Promise<FileInformation> {
  const base64 = await readFileAsBase64(file);
  const mime = base64.split(',')[0].split(';');

  return {
    file,
    base64,
    mimeType: mime[0].split(':')[1],
    filename: file.name,
  };
}

export function isValid<T>(value: T): value is NonNullable<T> {
  return !(value === null || value === undefined);
}

export function isDate(dateString: string) {
  return !isNaN(new Date(dateString).getDate());
}

export function enumValues(value: any): any[] {
  const isNumber = (i: any) => typeof i === 'number';

  const hasNumber = Object.values(value).some(isNumber);

  if (hasNumber) {
    return Object.values(value).filter(isNumber);
  }

  return Object.values(value);
}

export function normalizeWhitespaces(str: string): string {
  return str.replace(/ +(?= )/g, '');
}

export function getControlName(type: FormFieldType | CustomFieldType): string {
  if (type === FormFieldType.SELECT_ENTITY) return 'selectedId';

  if (type === FormFieldType.SELECT_ENUM) return 'selectedEnum';

  if (type === FormFieldType.TOGGLE) return 'active';

  if (type === FormFieldType.LABEL) return 'selectedIds';

  if (type === FormFieldType.SELECT || type === CustomFieldType.SELECT)
    return 'selectedIndex';

  return 'value';
}

export function createValueInForms(
  field: AssetFormFieldTemplateProxy | AssetFormFieldProxy,
): FormGroup {
  return new FormGroup({
    ...(field.type === FormFieldType.TEXT && {
      value: new FormControl('value' in field ? field.value['value'] : null, {
        validators: [...(field.isRequired ? [Validators.required] : [])],
      }),
    }),
    ...(field.type === FormFieldType.TEXTAREA && {
      value: new FormControl('value' in field ? field.value['value'] : null, {
        validators: [...(field.isRequired ? [Validators.required] : [])],
      }),
    }),
    ...(field.type === FormFieldType.INTEGER && {
      value: new FormControl('value' in field ? field.value['value'] : null, {
        validators: [...(field.isRequired ? [Validators.required] : [])],
      }),
    }),
    ...(field.type === FormFieldType.FLOAT && {
      value: new FormControl('value' in field ? field.value['value'] : null, {
        validators: [...(field.isRequired ? [Validators.required] : [])],
      }),
    }),
    ...(field.type === FormFieldType.DATE && {
      value: new FormControl('value' in field ? field.value['value'] : null, {
        validators: [...(field.isRequired ? [Validators.required] : [])],
      }),
    }),
    ...(field.type === FormFieldType.SELECT && {
      selectedIndex: new FormControl(
        'value' in field
          ? field.value['selectedIndex']
          : field.configuration['defaultOptionIndex'] || null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === FormFieldType.SELECT_ENTITY && {
      selectedId: new FormControl(
        'value' in field ? field.value['selectedId'] : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === FormFieldType.SELECT_ENUM && {
      selectedEnum: new FormControl(
        'value' in field ? field.value['selectedEnum'] : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === FormFieldType.TOGGLE && {
      active: new FormControl(
        'value' in field ? field.value['active'] : false,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === FormFieldType.LABEL && {
      selectedIds: new FormControl(
        'value' in field ? field.value['selectedIds'] : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === FormFieldType.ANVISA && {
      registerNumber: new FormControl(
        'value' in field ? field.value['registerNumber'] : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
      expirationDate: new FormControl(
        'value' in field ? field.value['expirationDate'] : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
      situation: new FormControl(
        'value' in field ? field.value['situation'] : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
  });
}

export function createValueCustomFields(
  field:
    | AssetCustomFieldProxy
    | AssetCustomFieldTemplateProxy
    | SubmitCustomFieldWithOptionalTemplatePayload,
): FormGroup {
  return new FormGroup({
    ...(field.type === CustomFieldType.TEXT && {
      value: new FormControl(
        'value' in field
          ? field.value['value']
          : field.configuration.defaultValue
            ? field.configuration.defaultValue
            : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === CustomFieldType.INTEGER && {
      value: new FormControl(
        'value' in field
          ? field.value['value']
          : field.configuration.defaultValue
            ? field.configuration.defaultValue
            : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === CustomFieldType.FLOAT && {
      value: new FormControl(
        'value' in field
          ? field.value['value']
          : field.configuration.defaultValue
            ? field.configuration.defaultValue
            : null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
    ...(field.type === CustomFieldType.SELECT && {
      selectedIndex: new FormControl(
        'value' in field
          ? field.value['selectedIndex']
          : field.configuration['defaultOptionIndex'] || null,
        {
          validators: [...(field.isRequired ? [Validators.required] : [])],
        },
      ),
    }),
  });
}
