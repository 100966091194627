import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticateGuard } from './guards/authenticate/authenticate.guard';

export const unAuthenticatedRoute = {
  canActivate: [AuthenticateGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenAuthenticated,
    unprotectedRoute: true,
  },
};

export const authenticatedRoute = {
  canActivate: [AuthenticateGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenUnauthenticated,
    protectedRoute: true,
  },
};

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    ...authenticatedRoute,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
    ...unAuthenticatedRoute,
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
    ...unAuthenticatedRoute,
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
