//#region Imports

import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalProgressService } from 'src/app/services/global-progress/global-progress.service';

//#endregion

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  constructor(private readonly globalProgressService: GlobalProgressService) {}

  //#region Methods

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.reportProgress) {
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.DownloadProgress) {
            const progress = Math.round(
              (event.loaded / (event.total || 100)) * 100,
            );
            this.globalProgressService.setCurrentProgres(progress);
          } else if (event.type === HttpEventType.Response) {
            this.globalProgressService.setCurrentProgres(null);
          }

          return event;
        }),
        finalize(() => {
          this.globalProgressService.setCurrentProgres(null);
        }),
      );
    } else {
      return next.handle(req);
    }
  }

  //#endregion
}
