//#region Imports

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError, concatAll, filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { JwtTokenProxy } from '@app/models/proxies/jwt-token.proxy';
import { AuthService } from '@app/services/auth/auth.service';
import { jwtDecode } from 'jwt-decode';
import { UserService } from '@app/services/user/user.service';

//#endregion

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly userService: UserService,
  ) {}

  //#region Private Properties

  private readonly refreshState$ = new BehaviorSubject<{
    refreshing: boolean;
    token?: JwtTokenProxy;
  }>({ refreshing: false });

  private sessionDidExpire: boolean = false;

  //#endregion

  //#region Public Methods

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return fromPromise(this.canPerformRequest()).pipe(
      map((canPerform) => {
        if (!canPerform) {
          throw new HttpErrorResponse({
            error: {
              message: 'A sua sessão expirou, você precisa logar novamente.',
            },
            status: 401,
          });
        }

        this.sessionDidExpire = false;

        return next.handle(req);
      }),
      concatAll(),
      catchError((error) => {
        if (error.status !== 401) throw error;

        if (req.url.includes('/auth/local')) throw error;

        this.authService.logout().then(async () => {
          if (this.sessionDidExpire) return;

          this.sessionDidExpire = true;

          await this.router.navigateByUrl(
            environment.config.redirectToWhenUnauthenticated,
          );
        });

        throw error;
      }),
    );
  }

  //#endregion

  //#region Private Methods

  private async tryRefreshToken(
    refreshToken: string,
  ): Promise<JwtTokenProxy | undefined> {
    if (this.refreshState$.value.refreshing) {
      const state = await firstValueFrom(
        this.refreshState$.pipe(filter((x) => !x.refreshing)).pipe(take(1)),
      );
      if (state.token) return state.token;
    }

    this.refreshState$.next({ refreshing: true });

    const proxy: JwtTokenProxy | undefined = await fetch(
      environment.api.baseUrl + environment.api.routes.auth.refresh,
      {
        method: 'POST',
        headers: {
          Authorization: refreshToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then(async (result) => (result.ok ? await result.json() : undefined))
      .catch(() => undefined);

    this.refreshState$.next({ refreshing: false, token: proxy });

    return proxy;
  }

  private isTokenExpired(token: string, maxExpiresDate: number): boolean {
    const jwtPayload: { exp: number } = jwtDecode(token);

    return maxExpiresDate >= +new Date(jwtPayload.exp * 1000);
  }

  private async canPerformRequest(): Promise<boolean> {
    const token = await this.userService.getUserToken();

    if (!token || !token.token) return true;

    const fiveSecondsInMilliseconds = 1_000 * 5;
    const maxSafeExpiresDate = +new Date() + fiveSecondsInMilliseconds;

    if (!this.isTokenExpired(token.token, maxSafeExpiresDate)) return true;

    if (
      !token.refreshToken ||
      this.isTokenExpired(token.refreshToken, maxSafeExpiresDate)
    )
      return false;

    const proxy = await this.tryRefreshToken(token.refreshToken);

    if (!proxy) return false;

    await this.userService.setUserToken(proxy);

    return true;
  }

  //#endregion
}
