import { SettingKey } from '@app/models/enums/setting-key.enum';
import { AssetType } from 'src/app/models/enums/asset-type.enum';

export const apiRoutes = {
  auth: {
    login: '/auth/local',
    refresh: '/auth/refresh',
  },
  users: {
    base: '/users',
    search: '/users/search',
    byId: (userId: string) => `/users/${userId}`,
    me: '/users/me',
    forgotPassword: (email: string) => `/users/password/forgot/email/${email}`,
    resetPassword: (code: string) => `/users/password/reset/${code}`,
    checkResetPasswordCode: (code: string) =>
      `/users/password/validate/${code}`,
    activate: (userId: string) => `/users/${userId}/activate`,
    inactivate: (userId: string) => `/users/${userId}/inactivate`,
  },
  families: {
    base: '/families',
    assetCount: (options: { pageLimit?: number }) =>
      `/families/count${
        options.pageLimit ? '?pageLimit=' + options.pageLimit : ''
      }`,
    byId: (familyId: string) => `/families/${familyId}`,
    activate: (familyId: string) => `/families/${familyId}/activate`,
    delete: (familyId: string) => `/families/${familyId}/delete`,
    totalCount: '/families/total-count',
    search: '/families/search',
  },
  familyTemplates: {
    base: '/family-form-templates',
    byId: (familyFormId: string) => `/family-form-templates/${familyFormId}`,
  },
  models: {
    base: '/models',
    byId: (modelId: string) => `/models/${modelId}`,
    activate: (modelId: string) => `/models/${modelId}/activate`,
    delete: (modelId: string) => `/models/${modelId}/delete`,
    search: '/models/search',
    attachmentFiles: '/model-attachment-files',
    formTemplates: (familyId?: string) =>
      `/models/form-templates?familyId=${familyId || null}`,
    nameSimilarity: (name: string) => `/models/${name}/similarity`,
    assetCount: '/models/count',
  },
  assets: {
    base: '/assets',
    byId: (assetId: string) => `/assets/${assetId}`,
    formTemplates: ({
      familyId,
      modelId,
      type,
    }: {
      familyId: string;
      modelId?: string;
      type?: AssetType;
    }) =>
      `/assets/form-templates?familyId=${familyId}${
        modelId ? '&modelId=' + modelId : ''
      }${type ? '&type=' + type : ''}`,
    inactivate: (assetId: string) => `/assets/${assetId}/inactivate`,
    activate: (assetId: string) => `/assets/${assetId}/activate`,
    delete: (assetId: string) => `/assets/${assetId}/delete`,
    inactiveMany: '/assets/inactive-many',
    activeMany: '/assets/active-many',
    unlinkFromParent: (assetId: string) =>
      `/assets/${assetId}/unlink-from-parent`,
    search: '/assets/search',
    many: '/assets/many',
    updateMany: '/assets/update-many',
    attachmentFiles: '/asset-attachment-files',
    attachmentFilesById: (attachmentId: string) =>
      `/asset-attachment-files/${attachmentId}`,
    getBatchEditFields: '/assets/get-batch-edit-fields',
  },
  assetCustomFieldFolders: {
    base: '/family-asset-custom-field-folders',
  },
  sectors: {
    base: '/sectors',
    byId: (sectorId: string) => `/sectors/${sectorId}`,
    delete: (sectorId: string) => `/sectors/${sectorId}/delete`,
    search: '/sectors/search',
    details: (sectorId: string) => `/sectors/${sectorId}/details`,
    inactivate: (sectorId: string) => `/sectors/${sectorId}/inactivate`,
    inactivateMany: '/sectors/inactivate-many',
  },
  workTeams: {
    base: '/work-teams',
    byId: (id: string) => `/work-teams/${id}`,
    activate: (id: string) => `/work-teams/${id}/activate`,
    inactivate: (id: string) => `/work-teams/${id}/inactivate`,
    search: '/work-teams/search',
  },
  serviceOrders: {
    base: '/service-orders',
    byId: (id: string) => `/service-orders/${id}`,
    open: (id: string) => `/service-orders/${id}/open`,
    cancel: (id: string) => `/service-orders/${id}/cancel`,
    cancelMany: `/service-orders/cancel-many`,
    openMany: `/service-orders/open-many`,
    search: '/service-orders/search',
  },
  serviceOrderPrioritySuggestions: {
    base: '/service-order-priority-suggestions',
    byId: (id: string) => `/service-order-priority-suggestions/${id}`,
    searchOne: `/service-order-priority-suggestions/search-one`,
    search: '/service-order-priority-suggestions/search',
    deleteMany: '/service-order-priority-suggestions/delete-many',
  },
  serviceOrderProblems: {
    base: '/service-order-problems',
    byId: (id: string) => `/service-order-problems/${id}`,
    activate: (problemsId: string) =>
      `/service-order-problem/${problemsId}/activate`,
    activateMany: '/service-order-problem/activate-many',
    inactivate: (problemsId: string) =>
      `/service-order-problem/${problemsId}/inactivate`,
    inactivateMany: '/service-order-problem/inactivate-many',
    deleteMany: '/service-order-problem/delete-many',
  },
  serviceOrderProblemCauses: {
    base: '/service-order-problem-causes',
    byId: (id: string) => `/service-order-problem-causes/${id}`,
  },
  costCenters: {
    base: '/cost-centers',
    search: '/cost-centers/search',
    byId: (id: string) => `/cost-centers/${id}`,
    activate: (id: string) => `/cost-centers/${id}/activate`,
    inactivate: (id: string) => `/cost-centers/${id}/inactivate`,
    delete: (id: string) => `/cost-centers/${id}/delete`,
    activateMany: '/cost-centers/activate-many',
    inactivateMany: '/cost-centers/inactivate-many',
    deleteMany: '/cost-centers/delete-many',
  },
  labels: {
    base: '/labels',
  },
  manufacturers: {
    base: '/manufacturers',
    byId: (manufacturerId: string) => `/manufacturers/${manufacturerId}`,
    activate: (manufacturerId: string) =>
      `/manufacturers/${manufacturerId}/activate`,
    activateMany: '/manufacturers/activate-many',
    inactivate: (manufacturerId: string) =>
      `/manufacturers/${manufacturerId}/inactivate`,
    inactivateMany: '/manufacturers/inactivate-many',
    deleteMany: '/manufacturers/delete-many',
  },
  suppliers: {
    base: '/suppliers',
    search: '/suppliers/search',
    byId: (supplierId: string) => `/suppliers/${supplierId}`,
  },
  contact: {
    base: '/contacts',
    byId: (contactId: string) => `/contacts/${contactId}`,
  },
  operatingSchedules: {
    base: '/operating-schedules',
    byId: (scheduleId: string) => `/operating-schedules/${scheduleId}`,
  },
  operatingSchedulesTemplates: {
    base: '/operating-schedule-templates',
    byId: (scheduleId: string) => `/operating-schedule-templates/${scheduleId}`,
  },
  location: {
    states: '/location/states',
    postalCode: '/location/by-postal-code/{postalCode}',
  },
  settings: {
    base: '/settings',
    byId: (id: string) => `/settings/${id}`,
    activate: (id: string) => `/settings/${id}/activate`,
    delete: (id: string) => `/settings/${id}/delete`,
    byKey: (key: SettingKey) => `/settings/by-key/${key}`,
  },
} as const;
