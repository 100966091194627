//#region Imports

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAclService, NbRoleProvider } from '@nebular/security';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/models/enums/user-role.enum';
import { UserService } from '../user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class RoleService implements NbRoleProvider {
  constructor(
    protected readonly router: Router,
    protected readonly nbAcl: NbAclService,
    protected readonly userService: UserService,
  ) {}

  //#region Public Methods

  public getRole(): Observable<UserRole[]> {
    return this.userService.getCurrentUser$().pipe(
      map((user) => {
        if (user) return user.roles;

        return [UserRole.USER];
      }),
    );
  }

  public async getRoleList(): Promise<UserRole[]> {
    const user = await this.userService.getCurrentUserFromStorage();

    if (!user) return [];

    return user.roles;
  }

  public async hasRole(role: UserRole): Promise<boolean> {
    const roles = await this.getRoleList();

    return roles.includes(role);
  }

  public async isGranted(action: string, resource: string): Promise<boolean> {
    const roles = await this.getRoleList();

    return roles.some((role) => this.nbAcl.can(role, action, resource));
  }

  //#endregion
}
