<div
  class="absolute top-0 left-0 h-screen w-screen flex align-content-center justify-content-center"
  [style.z-index]="showLoading ? 1 : -1"
  [class.opacity-0]="!showLoading"
  [class.opacity-100]="showLoading"
>
  <canvas
    class="m-auto fadein animation-duration-500"
    id="loading-page-canvas"
    width="300"
    height="300"
  >
  </canvas>
</div>
