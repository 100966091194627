//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class GlobalProgressService {
  //#region Private Properties

  private readonly progressSubject: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);

  //#endregion

  //#region Public Methods

  public getCurrentProgress$(): Observable<number | null> {
    return this.progressSubject.asObservable();
  }

  public setCurrentProgres(progress: number | null): void {
    this.progressSubject.next(progress);
  }

  //#endregion
}
