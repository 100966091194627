//#region Imports

import { Injectable } from '@angular/core';
import { BasePaginatedProxy } from '@app/models/proxies/base-paginated.proxy';
import {
  CrudRequestParams,
  createCrudUrl,
} from '@app/shared/pagination/models/crud';
import { Result } from '@app/shared/utils/result';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserCreatePayload } from 'src/app/models/payloads/user-create.payload';
import { UserUpdatePayload } from 'src/app/models/payloads/user-update.payload';
import { JwtTokenProxy } from 'src/app/models/proxies/jwt-token.proxy';
import { UserProxy } from 'src/app/models/proxies/user.proxy';
import { HttpAsyncService } from 'src/app/modules/http-async/services/http-async.service';
import {
  getCrudErrors,
  normalizeWhitespaces,
  setNullAtFalsyFields,
} from 'src/app/shared/utils/functions';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { BaseProxy } from "@app/models/proxies/base.proxy";
import { AsyncResult } from "../../modules/http-async/models/async-result";

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly http: HttpAsyncService,
    private readonly storage: StorageService,
  ) {}

  //#region Private Properties

  private readonly currentUser$: BehaviorSubject<UserProxy | null> =
    new BehaviorSubject<UserProxy | null>(null);

  //#endregion

  //#region Public Methods

  public getCurrentUser$(): Observable<UserProxy | null> {
    return this.currentUser$.asObservable();
  }

  public setCurrentUser(user: UserProxy | null): void {
    return this.currentUser$.next(user);
  }

  public async initializeUserInformation(): Promise<void> {
    await this.getMeAndSaveInStorage();
  }

  public async getCurrentUserFromStorage(): Promise<UserProxy | null> {
    const result = await this.storage.getItem<UserProxy>(environment.keys.user);

    if (!result) return null;

    this.currentUser$.next(result);

    return result;
  }

  public getCurrentUser(): UserProxy | null {
    return this.currentUser$.getValue();
  }

  public async getMe(): Promise<UserProxy | null> {
    const { error, success } = await this.http.get<UserProxy>(
      environment.api.routes.users.me,
    );

    if (error) throw new Error(getCrudErrors(error)[0]);

    return success!;
  }

  public async getMeAndSaveInStorage(): Promise<UserProxy> {
    const { error, success } = await this.http.get<UserProxy>(
      environment.api.routes.users.me,
    );

    if (error) throw new Error(getCrudErrors(error)[0]);

    await this.saveUserInStorage(success!);

    this.currentUser$.next(success!);

    return success!;
  }

  public async getUserToken(): Promise<JwtTokenProxy | null> {
    const token = await this.storage.getItem<JwtTokenProxy>(
      environment.keys.token,
    );

    if (!token) return null;

    return token;
  }

  public async setUserToken(token: JwtTokenProxy): Promise<void> {
    await this.storage.setItem<JwtTokenProxy>(environment.keys.token, token);
  }

  public async isLogged(): Promise<boolean> {
    const result = await this.storage.getItem<UserProxy>(
      environment.keys.token,
    );

    return !!result;
  }

  public async getById(
    entityId: string,
    crudParams?: CrudRequestParams<UserProxy>,
  ): Promise<UserProxy> {
    const endpoint = environment.api.routes.users.byId(entityId);
    const url = createCrudUrl<UserProxy>(endpoint, crudParams);

    const { error, success } = await this.http.get<UserProxy>(url);

    if (error) throw new Error(getCrudErrors(error)[0]);

    if (!success) {
      throw new Error('Não foi possível encontrar o usuário procurado.');
    }

    return success;
  }

  public async getByIds(ids: string[]): Promise<Result<UserProxy[], string>> {
    const endpoint = environment.api.routes.users.base;

    const url = createCrudUrl<UserProxy>(endpoint, {
      search: {
        id: { $in: ids },
        isActive: true,
      },
      limit: 50,
    });

    const { error, success } =
      await this.http.get<BasePaginatedProxy<UserProxy>>(url);

    if (error) return Result.error(getCrudErrors(error)[0]);

    if (!success) {
      return Result.error(
        'Ocorreu um erro inesperado ao buscar as informações',
      );
    }

    return Result.ok(success.data);
  }

  public async create(payload: UserCreatePayload): Promise<void> {
    payload.name = normalizeWhitespaces(payload.name.trim());
    payload.username = payload.username.trim();
    payload.password = payload.password.trim();

    const { error } = await this.http.post(
      environment.api.routes.users.base,
      setNullAtFalsyFields(payload),
    );

    if (error) throw new Error(getCrudErrors(error)[0]);
  }

  public async update(
    entityId: string,
    payload: UserUpdatePayload,
  ): Promise<void> {
    if (payload.name) payload.name = normalizeWhitespaces(payload.name.trim());
    if (payload.username) payload.username = payload.username.trim();

    const endpoint = environment.api.routes.users.byId(entityId);

    const { error } = await this.http.put(
      endpoint,
      setNullAtFalsyFields(payload),
    );

    if (error) throw new Error(getCrudErrors(error)[0]);

    const currentUser = this.getCurrentUser();

    if (currentUser?.id === entityId) await this.getMeAndSaveInStorage();
  }

  public async activate(entityId: string, payload: object | BaseProxy): Promise<Result<null, string>> {
    const url = environment.api.routes.users.activate(entityId);

    const { error } = await this.http.patch<void>(url, payload);

    if (error)
      return Result.error(getCrudErrors(error)[0]);

    return Result.ok(null);
  }

  public async inactivate(entityId: string, payload: object | BaseProxy): Promise<Result<null, string>> {
    const url = environment.api.routes.users.inactivate(entityId);

    const { error } = await this.http.patch<void>(url, payload);

    if (error)
      return Result.error(getCrudErrors(error)[0]);

    return Result.ok(null);
  }

  public async delete(entityId: string): Promise<Result<null, string>> {
    const url = environment.api.routes.users.byId(entityId);

    const { error } = await this.http.delete<void>(url);

    if (error)
      return Result.error(getCrudErrors(error)[0]);

    return Result.ok(null);
  }

  //#endregion

  //#region Private Methods

  private async saveUserInStorage(user: UserProxy): Promise<void> {
    await this.storage.setItem<UserProxy>(environment.keys.user, user);
  }

  //#endregion
}
