import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingPageModule } from './components/loading-page/loading-page.module';
import { HttpAsyncModule } from './modules/http-async/http-async.module';
import { BaseUrlInterceptor } from './modules/http-async/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http-async/interceptors/bearer-token.interceptor';
import { HttpAsyncHeadersInterceptor } from './modules/http-async/interceptors/http-async-headers.interceptor';
import { ProgressInterceptor } from './modules/http-async/interceptors/progress.interceptor';
import { RefreshTokenInterceptor } from './modules/http-async/interceptors/refresh-token.interceptor';
import { RetryInterceptor } from './modules/http-async/interceptors/retry.interceptor';
import { RoleService } from './services/role/role.service';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localePt, 'pt');

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    ButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpAsyncModule.withConfig({
      baseUrl: environment.api.baseUrl,
      bearerTokenKey: environment.keys.token,
      defaultHeaders: {
        Accept: 'application/json',
      },
    }),
    NbSecurityModule.forRoot(environment.rolePermissions),
    ToastModule,
    DialogModule,
    LoadingPageModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAsyncHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true },
    { provide: NbRoleProvider, useClass: RoleService },
    { provide: LOCALE_ID, useValue: 'pt' },
    MessageService,
    DialogService,
    ConfirmationService,
  ],
})
export class AppModule {}
