export enum FormFieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  INTEGER = 'integer',
  FLOAT = 'float',
  SELECT = 'select',
  SELECT_ENTITY = 'select-entity',
  SELECT_ENUM = 'select-enum',
  TOGGLE = 'toggle',
  DATE = 'date',
  LABEL = 'label',
  ANVISA = 'anvisa',
  OPERATING_SCHEDULE = 'operating-schedule',
  ATTACHMENT_FILE = 'attachment-file',
}
