import { NbAclOptions, NbAclRole } from '@nebular/security/security.options';
import { UserRole } from 'src/app/models/enums/user-role.enum';

type ControlObject = {
  [K in UserRole]: NbAclRole;
};

const accessControl: ControlObject = {
  admin: {
    view: '*',
    update: '*',
    create: '*',
    remove: '*',
  },
  user: {
    view: [],
    update: [],
    create: [],
    remove: [],
  },
};

export const rolePermissions: NbAclOptions = { accessControl };
