import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginPayload } from 'src/app/models/payloads/login.payload';
import { JwtTokenProxy } from 'src/app/models/proxies/jwt-token.proxy';
import { HttpAsyncService } from 'src/app/modules/http-async/services/http-async.service';
import { getCrudErrors } from 'src/app/shared/utils/functions';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpAsyncService,
    private readonly userService: UserService,
    private readonly storage: StorageService,
    private readonly router: Router,
  ) {}

  public async login(payload: LoginPayload): Promise<void> {
    const { error, success } = await this.http.post<JwtTokenProxy>(
      environment.api.routes.auth.login,
      payload,
    );

    if (error) throw new Error(getCrudErrors(error)[0]);

    if (!success) {
      throw new Error(
        'Não foi possível realizar o login, tente novamente mais tarde.',
      );
    }

    await this.storage.setItem<JwtTokenProxy>(environment.keys.token, success);
    await this.userService.getMeAndSaveInStorage();
  }

  public async logout(): Promise<void> {
    this.userService.setCurrentUser(null);
    await this.storage.clear();

    await this.router.navigateByUrl(
      environment.config.redirectToWhenUnauthenticated,
      { replaceUrl: true },
    );
  }
}
